  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-8-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert fissure Provence-Alpes-Côte d'Azur</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’Expert technique fissures</h1>

<p>L’Expert fissure est un professionnel du bâtiment maîtrisant les techniques de la construction et ayant une parfaite connaissance des pathologies de la construction.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert fissure Provence-Alpes-Côte d'Azur" />
</div>


<p>L’expert technique fissures a pour mission d’évaluer les dommages de type “fissures” affectant votre ouvrage, d’en déterminer les causes et origines qu’il retranscrit dans un rapport.</p>

<p>Il préconise les travaux de travaux de réparation les plus adaptés afin de recouvrer un ouvrage structurellement sain et pérenne et estime leurs coûts.</p>
<p>Il accompagne également les assurés lors d’opérations de contre-expertise amiable ou en judiciaire afin de <u>défendre les intérêts de l'assuré.</u></p>

<h2>L’Expertise technique fissure et expertise d’assuré</h2>

<p>L’Expert technique fissure intervient dans le cadre d’une expertise unilatérale, c'est-à-dire pour des besoins ponctuels d’avis technique</p>

<p>Lorsqu’un ouvrage présente des fissures, les questions les plus courantes sont : </p>

<ul><li>D’où proviennent ces fissures ?</li>
<li>Ces fissures sont-elles “graves” ? </li>
<li>Engagent-elles la solidité structurelle de l’ouvrage ?</li>
<li>Quels sont les travaux de réparation les plus adaptés ?</li></ul>

<p>L’Expert d’assuré “fissures”, quant à lui, intervient lors de litiges ou conflits opposant un particulier à un constructeur ou une assurance, par exemple dans le cadre d’un litige de dossier CatNat sécheresse ou lors de malfaçons constatées sur un chantier. </p>

<p>Il endosse un rôle de médiateur et d’assistant technique, dans un objectif de résolution amiable du conflit afin que l'assuré obtienne la juste indemnisation de son sinistre.</p>

<h2>Notre zone géographique d’intervention en région PACA</h2>

<ul><li>département Bouches-du-Rhône (13)</li>
<li>Vaucluse (84)</li>
<li>Var (83)</li>
<li>Alpes maritimes (06)</li>
<li>Hautes Alpes (05)</li>
<li>Alpes de Haute-Provence (84)</li></ul>


<p>Pour plus d’informations, n’hésitez pas à contacter notre expert fissure.</p>


<p> </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert fissure</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-fissure-provence-alpes-cote-azur/' className='active'>Expert fissure Provence-Alpes-Côte d'Azur</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details